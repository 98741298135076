import { ChangeEvent, useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "usehooks-ts"
import type {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface"

export const PAGINATION_LIMIT = 15

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = parseInt(searchParams.get("limit") ?? "15")
  const skip = parseInt(searchParams.get("skip") ?? "0")
  const searchTerm = searchParams.get("searchTerm") ?? ""
  const sort = searchParams.get("sort") ?? ""
  const sortOrder = searchParams.get("sortOrder") ?? "-1"
  const searchTermDebounced = useDebounce(searchTerm, 250)
  const text = searchTermDebounced.length > 2 ? searchTermDebounced : ""

  const handlePageChange = (skip: number) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set("skip", skip.toString())
      return newParams
    })
  }
  const handlePageSizeChange = (pageSize: number) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set("limit", pageSize.toString())
      newParams.set("skip", "0")
      return newParams
    })
  }
  const handleSearchTermChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) =>
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev)
        value
          ? newParams.set("searchTerm", value)
          : newParams.delete("searchTerm")
        if (skip !== 0 && value.length > 2) {
          newParams.set("skip", "0")
        }
        return newParams
      },
      { replace: true },
    )

  const handleSortFieldChange = useCallback(
    (
      p: TablePaginationConfig,
      f: Record<string, FilterValue | null>,
      field: SorterResult<any>,
    ) =>
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        const newPage = (p.current! - 1) * p.pageSize!
        newParams.set("skip", newPage.toString())
        if (field.order && field.column?.sorter === true) {
          newParams.set("sort", field.columnKey?.toString() ?? "")
          newParams.set("sortOrder", field.order === "ascend" ? "1" : "-1")
        } else {
          newParams.delete("sort")
          newParams.delete("sortOrder")
        }
        return newParams
      }),
    [setSearchParams],
  )

  return {
    limit,
    skip,
    searchTerm,
    text,
    sort,
    sortOrder,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
    handleSortFieldChange,
  }
}
