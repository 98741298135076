import { FC } from "react"
import cx from "classnames"
import { useParams } from "react-router-dom"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import Select from "@/components/Select"
import TextField from "@/components/TextField"
import LoginEmailModal from "../LoginEmailModal"
import validationSchema from "./validationSchema"
import { useGetEmailDomainsQuery } from "@/redux/services/domainsApi"
import { useAddEmailAddress } from "../../hooks/useAddEmailAddress"
import { useSenders } from "@/hooks/useSenders"
import type {
  AddEmailAddressPayload,
  AddEmailAddressResponse,
} from "@/types/entities/email-address"

type Props = {
  className?: string
  addedEmail?: AddEmailAddressResponse | null
  onAddedEmailChange: React.Dispatch<
    React.SetStateAction<AddEmailAddressResponse | null | undefined>
  >
} & ModalProps

const AddEmailModal: FC<Props> = ({
  className,
  open,
  onClose,
  addedEmail,
  onAddedEmailChange,
  ...rest
}: Props) => {
  const { id: orgId = "" } = useParams()
  const {
    addEmailAddress,
    response: { isLoading: addLoading },
  } = useAddEmailAddress()
  const { senders, isLoading: sendersLoading } = useSenders({
    refetchOnMountOrArgChange: true,
  })
  const {
    data: { items: domains } = { items: [] },
    isLoading: domainsLoading,
  } = useGetEmailDomainsQuery(
    {
      id: orgId,
    },
    { refetchOnMountOrArgChange: true },
  )
  const {
    dirty,
    isValid,
    resetForm,
    values: { id, domainId },
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = useFormik<Omit<AddEmailAddressPayload, "username"> & { id: string }>({
    initialValues: {
      id: "",
      domainId: "",
      dailyLimit: 25,
    },
    validationSchema,
    onSubmit: async ({ domainId, id: senderId, dailyLimit }) => {
      const selectedSender = senders.find(({ _id }) => _id === senderId)
      try {
        const emailResponse = await addEmailAddress({
          id: senderId,
          body: {
            domainId,
            dailyLimit,
            username: `${selectedSender?.firstName ?? ""}`,
          },
        })
        onAddedEmailChange(emailResponse)
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }

  const submitDisabled = !(dirty && isValid) || addLoading

  return (
    <>
      <Modal
        title="Add Email Address"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        className={cx("", className)}
        actions={
          <div className="flex gap-4">
            <OutlineButton
              type="button"
              className="btn-sm text-black min-w-[86px]"
              disabled={addLoading}
              onClick={handleClose}
            >
              Cancel
            </OutlineButton>
            <Button
              disabled={submitDisabled}
              loading={addLoading}
              type="submit"
              className="btn-sm min-w-[146px]"
            >
              Create & Login
            </Button>
          </div>
        }
        {...rest}
      >
        <div className="flex gap-5 flex-col [&_.label-text]:pl-0 [&_.label]:pl-0 [&_.label]:pt-0 [&_.label-text]:text-xs [&_.label-text]:leading-normal">
          <input type="tel" className="w-0 h-0 -mt-5" />
          <Select
            value={id || null}
            onChange={(v) => setFieldValue("id", v)}
            label="Sender"
            options={senders.map(({ _id, firstName, lastName, tag }) => ({
              label: `${firstName} ${lastName}${tag ? ` (${tag})` : ''}`,
              value: _id,
            }))}
            loading={sendersLoading}
            className="h-10 bg-input rounded-md"
            placeholder="Select sender"
            getPopupContainer={(node) => node.parentNode}
            bordered={false}
            autoFocus={false}
          />
          <Select
            value={domainId || null}
            onChange={(v) => setFieldValue("domainId", v)}
            label="Domain"
            options={domains.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            loading={domainsLoading}
            className="h-10 bg-input rounded-md"
            placeholder="Select domain"
            getPopupContainer={(node) => node.parentNode}
            bordered={false}
          />
          <TextField
            type="number"
            topLeftLabel="Daily New Volume"
            className="input-sm rounded-md"
            placeholder="25"
            {...getFieldProps("dailyLimit")}
          />
        </div>
      </Modal>
      <LoginEmailModal
        data={addedEmail}
        open={Boolean(addedEmail)}
        onClose={() => onAddedEmailChange(null)}
      />
    </>
  )
}

export default AddEmailModal
