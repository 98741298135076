import { FC, useState } from "react"
import { cx } from "@/utils/strings"
import { useParams } from "react-router-dom"
import { useGetSendersQuery } from "@/redux/services/sendersApi"
import SearchSelect from "@/components/SearchSelect"
import type { SelectProps } from "antd"
import type { DefaultOptionType } from "antd/es/select"
import type { SenderAPIResponse } from "@/types/entities/sender"

type Props = {
  className?: string
} & SelectProps<any>

const SendersDropdown: FC<Props> = ({ className, ...rest }: Props) => {
  const { id: orgId = "" } = useParams()
  const [searchTerm, setSearchTerm] = useState("")
  const { data: { items: senders } = { items: [] } } = useGetSendersQuery(
    { orgId },
    { skip: !orgId },
  )
  const filterOption = (input: string, option?: DefaultOptionType) =>
    ((option?.label ?? "") as string)
      .toLowerCase()
      .includes(input.toLowerCase())

  return (
    <SearchSelect
      options={[
        { firstName: "All", lastName: "senders", _id: "" } as SenderAPIResponse,
        ...senders,
      ].map(({ firstName, lastName, _id, tag }) => ({
        label: `${firstName} ${lastName}${tag ? ` (${tag})` : ''}`,
        value: _id,
      }))}
      className={cx("h-9 min-w-[176px]", className)}
      placeholder="Search sender name"
      filterOption={filterOption}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
      popupMatchSelectWidth={false}
      {...rest}
    />
  )
}

export default SendersDropdown
