import { FC, useId, useState } from "react"
import cx from "classnames"
import SearchSelect from "@/components/SearchSelect"
import dayjs from "dayjs"
import Checkbox from "@/components/Checkbox"
import IndicatorDropdown from "../IndicatorDropdown"
import DatePicker from "@/components/DatePicker"
import Select from "@/components/Select"
import Tooltip from "@/components/Tooltip"
import CalendarIcon from "@/components/icons/CalendarIcon"
import ClassificationsDropdown from "@/components/ClassificationsDropdown"
import { useDashboard } from "../../hooks/useDashboard"
import { filterOption } from "@/components/Select/constants"
import { useDashboardValues } from "../../hooks/useDashboardValues"

type Props = {
  className?: string
}

const DashboardFiltersRow: FC<Props> = ({ className, ...rest }: Props) => {
  const {
    campaigns,
    campaignsValue,
    grouping,
    handleCampaignChange,
    handleChangeDate,
    handleGroupingChange,
    handleMultipleChange,
    handleFieldChange,
    selectedCampaigns,
    from,
    to,
    multiple,
    masterList,
    emailAddress,
    sequence,
    sender,
    email,
    version,
  } = useDashboard()

  const { masterLists, sequences, senders, emailAddresses } =
    useDashboardValues()
  const resetOnSenderChange = () => {
    handleFieldChange("emailAddress")(undefined)
    handleFieldChange("email")(undefined)
    handleFieldChange("version")(undefined)
  }
  const resetOnSequenceChange = () => {
    handleFieldChange("email")(undefined)
    handleFieldChange("version")(undefined)
  }
  const resetOnEmailChange = () => {
    handleFieldChange("version")(undefined)
  }
  const multipleGraphsEnabledId = useId()
  const [campaignsSearchTerm, setCampaignsSearchTerm] = useState("")
  return (
    <div className={cx("flex flex-wrap gap-3", className)}>
      <SearchSelect
        optionRender={({ value, label }) => (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex items-center"
          >
            <Checkbox
              id={value as string}
              onChange={handleCampaignChange(value as string)}
              checked={Boolean(selectedCampaigns[value as string])}
              className="checkbox-primary h-[18px] w-[18px]"
            />
            <label
              htmlFor={value as string}
              className="flex-grow pl-2.5 flex gap-2 items-center cursor-pointer"
            >
              <span className="text-sm leading-6">{label}</span>
            </label>
          </div>
        )}
        searchTerm={campaignsSearchTerm}
        onSearch={setCampaignsSearchTerm}
        onBlur={() => setCampaignsSearchTerm("")}
        placeholder="Select campaigns"
        className="h-9 w-52"
        options={campaigns.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))}
        filterOption={filterOption}
        value={campaignsValue.label || null}
        popupMatchSelectWidth={false}
      />
      <ClassificationsDropdown className="max-w-[350px]" />
      <IndicatorDropdown />
      <div className="flex items-center">
        <DatePicker
          selected={from}
          onChange={handleChangeDate("from")}
          formatTextFieldValue={(v) => `${dayjs(v).format("M/D/YYYY")}`}
          inputClassName="!h-9 !w-[120px] sm:!w-36 rounded-md bg-white border-outline-dark border"
          suffixIcon={
            <CalendarIcon className="w-5 h-5 opacity-60 text-black" />
          }
          maxDate={dayjs(to).toDate()}
        />
        <span className="opacity-60 text-sm leading-6 mx-2">to</span>
        <DatePicker
          selected={to}
          onChange={handleChangeDate("to")}
          formatTextFieldValue={(v) => `${dayjs(v).format("M/D/YYYY")}`}
          inputClassName="!h-9 !w-[120px] sm:!w-36 rounded-md bg-white border-outline-dark border"
          suffixIcon={
            <CalendarIcon className="w-5 h-5 opacity-60 text-black" />
          }
          minDate={dayjs(from).toDate()}
        />
      </div>
      <Select
        value={grouping}
        onChange={handleGroupingChange}
        className="h-9 w-28"
        options={[
          { label: "Hourly", value: "hour" },
          { label: "Daily", value: "day" },
          { label: "Weekly", value: "week" },
          { label: "Monthly", value: "month" },
          { label: "Yearly", value: "year" },
        ]}
        bordered
      />
      <Select
        value={sender || null}
        onChange={handleFieldChange("sender")}
        options={senders.map(({ _id, lastName, firstName, tag }) => ({
          label: `${firstName} ${lastName}${tag ? ` (${tag})` : ''}`,
          value: _id,
        }))}
        placeholder="Select sender"
        className="h-9 w-40"
        onClear={resetOnSenderChange}
        onSelect={resetOnSenderChange}
        allowClear
      />
      <Tooltip title={sender ? null : "Select Sender"}>
        <Select
          value={emailAddress || null}
          onChange={handleFieldChange("emailAddress")}
          disabled={!sender}
          options={emailAddresses.map(({ _id, email }) => ({
            label: email,
            value: _id,
          }))}
          placeholder="Select Email Address"
          className="h-9 w-48"
          allowClear
        />
      </Tooltip>
      <Select
        value={masterList || null}
        onChange={handleFieldChange("masterList")}
        options={masterLists.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))}
        placeholder="Select Master List"
        className="h-9 w-44"
        allowClear
      />
      <Select
        value={sequence || null}
        onChange={handleFieldChange("sequence")}
        options={sequences.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))}
        placeholder="Select sequence"
        className="h-9 w-40"
        allowClear
        onClear={resetOnSequenceChange}
        onSelect={resetOnSequenceChange}
      />
      <Tooltip title={sequence ? null : "Select Sequence"}>
        <Select
          value={email || null}
          onChange={handleFieldChange("email")}
          disabled={!sequence}
          options={sequences
            .find(({ _id }) => _id === sequence)
            ?.emails.map((_, i) => ({
              label: i + 1,
              value: i.toString(),
            }))}
          placeholder="Select email number"
          className="h-9 w-40"
          allowClear
          onClear={resetOnEmailChange}
          onSelect={resetOnEmailChange}
        />
      </Tooltip>
      <Tooltip
        title={sequence ? (email ? null : "Select Email") : "Select Sequence"}
      >
        <Select
          value={version || null}
          onChange={handleFieldChange("version")}
          disabled={!(sequence && email)}
          options={sequences
            .find(({ _id }) => _id === sequence)
            ?.emails.find((_, i) => i === Number(email))
            ?.versions.map((_, i) => ({
              label: i + 1,
              value: i.toString(),
            }))}
          placeholder="Select version number"
          className="h-9 w-40"
          allowClear
        />
      </Tooltip>
      <div className="flex items-center">
        <Checkbox
          checked={multiple === "true"}
          onChange={({ target: { checked } }) => handleMultipleChange(checked)}
          disabled={!campaignsValue.value?.length}
          id={multipleGraphsEnabledId}
          className="checkbox-primary w-[18px] h-[18px]"
        />
        <label
          className={cx("text-sm leading-6 cursor-pointer pl-2.5", {
            "opacity-40 cursor-text": !campaignsValue.value?.length,
          })}
          htmlFor={multipleGraphsEnabledId}
        >
          Multiple graphs
        </label>
      </div>
    </div>
  )
}

export default DashboardFiltersRow
