import { FC, useEffect, useState, useRef } from "react"
import cx from "classnames"
import { useParams, useSearchParams } from "react-router-dom"
import { MdOutlineUploadFile as UploadFileIcon } from "react-icons/md"
import AddIcon from "@/components/icons/AddIcon"
import { usePageTitle } from "@/hooks/usePageTitle"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import SearchIcon from "@/assets/search.svg"
import OutlineButton from "@/components/OutlineButton"
import EmptyList from "@/components/EmptyList"
import { useGetUserDNCQuery } from "@/redux/services/userDncApi"
import UserDNCTable from "./features/UserDNCTable"
import AddEditDNCModal from "./features/AddEditDNCModal"
import ImportDNCModal from "./features/ImportDNCModal"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import Switch from "@/components/Switch"
import DownloadIcon from "@/components/icons/DownloadIcon"
import { POLLING_INTERVAL } from "@/constants"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useExportUserDNC } from "./hooks/useExportUserDNC"

type Props = {
  className?: string
}

const DNCPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Do Not Contact List")
  const { id: orgId = "" } = useParams()
  const [, setSearchParams] = useSearchParams()
  const [rect, ref] = useClientRect(true)
  const { pageFullAccess } = useUserAccess({ pageName: "DNC List" })
  const [showBounced, setShowBounced] = useState(false)
  const {
    searchTerm,
    skip,
    limit,
    text,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
  } = usePagination()
  const { exportUserDnc, isLoading: exportLoading } = useExportUserDNC()
  const {
    isLoading,
    isFetching,
    isError,
    data: { items: dncs, total } = { total: 0, items: [] },
  } = useGetUserDNCQuery(
    { limit, skip, orgId, text, bounced: showBounced },
    { pollingInterval: POLLING_INTERVAL }
  )
  const isInitialMount = useRef(true)
  const prevShowBounced = useRef(showBounced)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    if (prevShowBounced.current !== showBounced) {
      prevShowBounced.current = showBounced
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.set("skip", "0")
        return newParams
      })
    }
  }, [showBounced, setSearchParams])
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openImportModal, setOpenImportModal] = useState(false)

  const handleOpenAddModal = () => setOpenAddModal(true)
  const handleOpenImportModal = () => setOpenImportModal(true)

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("h-full flex flex-col", className)} {...rest}>
        <div className="flex justify-between gap-1 flex-wrap">
          <div className="flex gap-3 items-center">
            <TextField
              startIcon={<SearchIcon />}
              placeholder="Search DNC contact"
              rootClassName="basis-56"
              className="input-sm !h-9"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <div className="flex gap-1.5 items-center">
              <Switch
                id="dnc-show-bounced"
                checked={showBounced}
                onChange={setShowBounced}
              />
              <label
                htmlFor="dnc-show-bounced"
                className="cursor-pointer text-sm whitespace-nowrap"
              >
                <span className="hidden sm:inline">Include</span> Bounces
              </label>
            </div>
            {pageFullAccess && (
              <OutlineButton
                onClick={() =>
                  exportUserDnc({ bounced: showBounced, text: searchTerm })
                }
                className="btn-base text-black"
                loading={exportLoading}
              >
                <DownloadIcon className="opacity-80 w-5 h-5" />
                <span className="opacity-80 hidden sm:inline">Export</span>
              </OutlineButton>
            )}
          </div>
          {pageFullAccess && (
            <div className="flex gap-3">
              <OutlineButton
                onClick={handleOpenImportModal}
                className="btn-primary btn-base"
              >
                <UploadFileIcon className="w-6 h-6" />
                <span className="hidden sm:inline">Import</span>
              </OutlineButton>
              <Button onClick={handleOpenAddModal} className="btn-base">
                <AddIcon className="w-6 h-6" />
                <span className="hidden sm:inline">Add DNC Contact</span>
              </Button>
            </div>
          )}
        </div>
        <div
          className={cx(
            "flex-1 overflow-y-auto mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[380px]",
            { "justify-center items-center": !dncs.length },
          )}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {dncs.length ? (
                <div ref={ref} className="w-full">
                  <UserDNCTable
                    dncs={dncs}
                    total={total}
                    page={skip}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    maxHeight={rect?.height ?? 0}
                    loading={isLoading}
                  />
                </div>
              ) : (
                <EmptyList message="There are no DNC contacts yet">
                  {pageFullAccess && (
                    <div className="flex gap-3">
                      <OutlineButton
                        onClick={handleOpenImportModal}
                        className="btn-primary btn-base"
                      >
                        <UploadFileIcon className="w-6 h-6" />
                        <span className="hidden sm:inline">Import</span>
                      </OutlineButton>
                      <Button
                        onClick={handleOpenAddModal}
                        className="btn-base group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                      >
                        <AddIcon className="transition-colors text-primary group-hover:text-white" />
                        <span className="hidden sm:inline transition-colors font-medium text-primary group-hover:text-white">
                          Add DNC Contact
                        </span>
                      </Button>
                    </div>
                  )}
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditDNCModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <ImportDNCModal
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
    </>
  )
}

export default DNCPage
