import { FC, useMemo, useState } from "react"
import cx from "classnames"
import { useParams } from "react-router-dom"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import Select from "@/components/Select"
import { BiPlus as AddIcon } from "react-icons/bi"
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai"
import CancelIcon from "@/assets/cancel.svg"
import RadioGroup from "@/components/RadioGroup"
import Radio from "@/components/Radio"
import { filterOption } from "@/components/Select/constants"
import { useGetSendersQuery } from "@/redux/services/sendersApi"
import { getNumberEnding } from "@/utils/numbers"
import Checkbox from "@/components/Checkbox"
import { usePreviewSequence } from "../../hooks/usePreviewSequence"
import validationSchema from "./validationSchema"
import { removeUndefinedProperties } from "@/utils/objects"
import type {
  SequenceAPIResponse,
  SequencePreviewPayload,
} from "@/types/entities/sequence"
import type { RadioChangeEvent } from "antd"

type EmailType = "All" | "Custom"

type Props = {
  className?: string
  sequence?: SequenceAPIResponse | null
} & ModalProps

const TestSequenceModal: FC<Props> = ({
  className,
  open,
  onClose,
  sequence,
  ...rest
}: Props) => {
  const { id: orgId = "" } = useParams()
  const { data: { items: senders } = { items: [] }, isLoading } =
    useGetSendersQuery({
      orgId,
    })
  const {
    previewSequence,
    response: { isLoading: previewSequenceLoading },
  } = usePreviewSequence()
  const [emailsType, setEmailsType] = useState<"All" | "Custom">("All")
  const {
    values: { destEmails, sender, emails },
    setFieldValue,
    resetForm,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik<SequencePreviewPayload>({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      sender: "",
      emails:
        sequence?.emails.map((_, idx) => ({
          idx,
          versions: [],
          enabled: false,
        })) ?? [],
      destEmails: [""],
    },
    onSubmit: async (values) => {
      if (!sequence?._id) {
        return
      }
      const resultBody: typeof values = {
        ...values,
        emails: values.emails
          .filter(({ enabled }) => (emailsType === "All" ? true : enabled))
          .map(
            ({ idx, versions }) =>
              removeUndefinedProperties({
                idx,
                versions: versions?.length ? versions : undefined,
              }) as any,
          ),
      }

      const response = await previewSequence({
        body: resultBody,
        id: sequence._id,
      })
      if (response) {
        handleClose()
      }
    },
  })
  const emailCount = useMemo(() => {
    if (!sequence) {
      return 0
    }
    const allCount = sequence?.emails.reduce(
      (prev, curr) => prev + curr.versions.length,
      0,
    )
    if (emailsType === "All") {
      return allCount
    }
    if (
      emails.find(
        ({ versions, enabled }) => enabled || Boolean(versions?.length),
      )
    ) {
      return emails.reduce((prev, curr) => {
        const defaultVersionsCount =
          sequence?.emails.find((_, i) => i === curr.idx)?.versions.length || 0
        const resultWithVersionsCount = curr.enabled
          ? curr.versions?.length || defaultVersionsCount
          : 0
        return prev + resultWithVersionsCount
      }, 0)
    }
    return 0
  }, [emails, emailsType, sequence])
  if (!sequence) {
    return null
  }
  const handleClose = () => {
    resetForm()
    setEmailsType("All")
    onClose && onClose()
  }
  const handleAddDestEmail = () =>
    setFieldValue(`destEmails[${destEmails.length}]`, "")
  const handleDeleteDestEmail = (deletedI: number) => () =>
    setFieldValue(
      `destEmails`,
      destEmails.filter((_, i) => i !== deletedI),
    )
  const handleVersionSelect =
    (emailI: number) =>
    ({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
      const selectedVersion = parseInt(value)
      setFieldValue(
        "emails",
        checked
          ? emails.map((v) =>
              v.idx === emailI
                ? {
                    ...v,
                    versions: v.versions
                      ? v.versions.concat(selectedVersion)
                      : [selectedVersion],
                  }
                : v,
            )
          : emails.map((v) =>
              v.idx === emailI
                ? {
                    ...v,
                    versions: v.versions
                      ? v.versions.filter((v) => v !== selectedVersion)
                      : [],
                  }
                : v,
            ),
      )
    }
  const handleEmailTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    const v = value as EmailType
    setEmailsType(v)
    if (v === "All") {
      setFieldValue(
        "emails",
        sequence?.emails.map((_, idx) => ({
          idx,
          versions: [],
          enabled: false,
        })) ?? [],
      )
    }
  }
  const handleEmailToggle = ({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      "emails",
      emails.map((email) =>
        email.idx === parseInt(value)
          ? {
              ...email,
              enabled: checked,
            }
          : email,
      ),
    )
  }

  const submitDisabled =
    !(dirty && isValid) || previewSequenceLoading || emailCount === 0

  return (
    <Modal
      title="Test Sequence"
      open={open}
      className={cx("", className)}
      onClose={handleClose}
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={previewSequenceLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={submitDisabled}
            type="submit"
            className="btn-sm min-w-[88px]"
          >
            Send
          </Button>
        </div>
      }
      {...rest}
    >
      <div className="space-y-5">
        <Select
          value={sender || null}
          onChange={(v) => setFieldValue("sender", v)}
          showSearch
          filterOption={filterOption}
          options={senders.map(({ _id, firstName, lastName, tag }) => ({
            label: `${firstName} ${lastName}${tag ? ` (${tag})` : ''}`,
            value: _id,
          }))}
          loading={isLoading}
          bordered={false}
          className="bg-input h-10 rounded-md"
          label="Sender"
          placeholder="Select sender"
          getPopupContainer={(node) => node.parentNode}
        />
        <div className="flex flex-col gap-3">
          {destEmails.map((v, i) => (
            <div key={i} className="relative">
              <TextField
                value={v}
                onChange={({ target: { value } }) =>
                  setFieldValue(`destEmails[${i}]`, value)
                }
                topLeftLabel={i === 0 ? "Where should we send the test?" : null}
                placeholder="receiver@mail.com"
                type="email"
                className="input-sm"
                tabIndex={1}
              />
              {destEmails.length > 1 && (
                <CancelIcon
                  onClick={handleDeleteDestEmail(i)}
                  className="absolute -right-1.5 bottom-7 w-[18px] h-[18px] cursor-pointer active:scale-90"
                />
              )}
            </div>
          ))}
          <OutlineButton
            type="button"
            onClick={handleAddDestEmail}
            className="btn-base"
          >
            <AddIcon className="h-6 w-6 text-black" />
            <span className="text-black text-sm leading-6">Add Another</span>
          </OutlineButton>
        </div>
        <div className="space-y-2">
          <div className="text-sm leading-[18px] font-medium">
            What emails should we test?
          </div>
          <RadioGroup
            value={emailsType}
            onChange={handleEmailTypeChange}
            className="font-medium"
          >
            <Radio value="All">All emails and versions</Radio>
            <Radio value="Custom">Custom</Radio>
          </RadioGroup>
        </div>
        {emailsType === "Custom" && (
          <table className="w-full" cellPadding={8}>
            <thead className="text-xs leading-[22px] border-b border-b-outline-dark">
              <tr>
                <th className="w-12"></th>
                <th className="w-36 text-start">#</th>
                <th className="text-start">Versions</th>
              </tr>
            </thead>
            <tbody>
              {sequence.emails.map(({ _id, versions }, emailI) => (
                <tr className="border-b border-b-outline" key={_id}>
                  <td>
                    <Checkbox
                      id={`email-version-${emailI}`}
                      checked={emails[emailI].enabled}
                      onChange={handleEmailToggle}
                      value={emailI}
                      className="checkbox-primary w-[18px] h-[18px] translate-y-0.5"
                    />
                  </td>
                  <td className="font-semibold text-sm leading-6">
                    <label
                      className="cursor-pointer"
                      htmlFor={`email-version-${emailI}`}
                    >
                      {emailI + 1}
                      {getNumberEnding(emailI + 1)} Email
                    </label>
                  </td>
                  <td className="py-3">
                    <Select
                      value={
                        emails[emailI]?.versions?.length
                          ? emails[emailI].versions
                              ?.map((v) => `Version ${v + 1}`)
                              ?.join(", ")
                          : null
                      }
                      disabled={!emails[emailI].enabled}
                      bordered={false}
                      className="h-10 rounded-md bg-input"
                      placeholder="Select versions"
                      options={versions.map((_, versionI) => ({
                        label: `Version ${versionI + 1}`,
                        value: versionI,
                      }))}
                      getPopupContainer={(node) => node.parentNode}
                      optionRender={({ value: versionI, label }) => {
                        const emailIndex = emails.findIndex(
                          ({ idx }) => idx === (emailI as number),
                        )
                        return (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className="flex items-center"
                          >
                            <Checkbox
                              id={`${emailI}-${versionI}`}
                              value={versionI}
                              onChange={handleVersionSelect(emailI)}
                              checked={
                                emailIndex !== -1 &&
                                emails[emailIndex].versions &&
                                emails[emailIndex].versions!.findIndex(
                                  (currentV) => currentV === versionI,
                                ) !== -1
                              }
                              className="checkbox-primary h-[18px] w-[18px]"
                            />
                            <label
                              htmlFor={`${emailI}-${versionI}`}
                              className="flex-grow pl-2.5 flex gap-2 items-center cursor-pointer"
                            >
                              <span className="text-sm leading-6">{label}</span>
                            </label>
                          </div>
                        )
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="flex items-center gap-2 rounded-md text-sm leading-6 p-2 bg-[#0096F214] border border-[#0096F229] text-info">
          <InfoIcon className="w-6 h-6" />
          <span>
            This test will send {emailCount}{" "}
            {emailCount === 1 ? "email" : "emails"}
          </span>
        </div>
      </div>
    </Modal>
  )
}

export default TestSequenceModal
