import { array, object, string, lazy, mixed } from "yup"
import { email } from "@/core/validation"

const addressSchema = object({
  countryCode: string().required("Required").oneOf(["US", "CA", "Other"]),
  address1: string().when("countryCode", {
    is: (value: string) => value === "US" || value === "CA",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
  city: string().when("countryCode", {
    is: (value: string) => value === "US" || value === "CA",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
  state: string().when("countryCode", {
    is: (val: string) => val === "US",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
  zipCode: mixed().when("countryCode", {
    is: (val: string) => val === "US",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
  province: string().when("countryCode", {
    is: (val: string) => val === "CA",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
  postalCode: string().when("countryCode", {
    is: (val: string) => val === "CA",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
  completeAddress: string().when("countryCode", {
    is: (val: string) => val === "Other",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.optional(),
  }),
})
export default object({
  firstName: string().required("Required"),
  lastName: string().required("Required"),
  email,
  tag: string(),
  phoneNumber: string(),
  companies: lazy((val) =>
    Array.isArray(val) ? array().of(string()) : string(),
  ),
  websites: lazy((val) =>
    Array.isArray(val) ? array().of(string()) : string(),
  ),
  titles: lazy((val) =>
    Array.isArray(val)
      ? array().of(string().required("Required"))
      : string().required("Required"),
  ),
  address: addressSchema,
})
